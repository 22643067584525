<template>
  <v-container>
    <v-card>
      <v-card-title>Mailing Listen</v-card-title>
      <v-data-table :headers="headers" :items="lists" :items-per-page="10">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            :to="{ name: 'MailListEdit', params: { address: item.address } }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="deleteList(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn :to="{ name: 'MailListEdit' }">Neue Liste</v-btn>
      </v-card-actions>
    </v-card>
    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>

import emailService from '@/services/email'
import Confirm from '@/components/Confirm'
import { createNotification } from '@/utils'

export default {
  name: 'MailingLists',
  components: { Confirm },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Beschreibung', value: 'description' },
        { text: 'Adresse', value: 'address' },
        { text: 'Anzahl Empfänger', value: 'members_count' },
        { text: '', value: 'actions', sortable: false, align: 'right' },
      ],
      lists: [],
    }
  },
  methods: {
    deleteList(list) {
      let txt = `${list.name} (${list.address}) endgültig löschen? All Mailadressen werden von der Liste entfernt und die Liste gelöscht.`
      txt += ' Diese Aktion kann nicht rückgängig gemacht werden!'
      this.$refs.confirm
        .open('Löschen bestätigen', txt, { color: 'error' })
        .then(confirm => {
          if (confirm) {
            emailService.deleteMailingList(list.address)
              .then(data => {
                createNotification('Mailingliste gelöscht!', 'success')
                this.fetchMailingLists()
              })
          }
        })
    },
    fetchMailingLists() {
      emailService.getMailingLists()
        .then(data => {
          this.lists = data
        })
    },
  },
  mounted() {
    this.fetchMailingLists()
  },
}
</script>

<style>
</style>
